<template>
	<div class="wrap">
		<div class="content-center" v-if="isDoctor">
			<div class="input">
				<van-field v-model="name" type="text" clearable label="当前微信号真实姓名" placeholder="请输入当前微信号的真实姓名" @input="onChangeText" />
			</div>
			<div class="center-button" :class="btnEnable ? 'enabled' : 'disabled'" @click="handleSubmit">
				<span>提交</span>
			</div>
		</div>
		<div v-else class="tips">
			<h2>温馨提示</h2>
			<div>
				<p>请先到瑞云医医生端完成医生审核信息</p>
				<p>(该手机号未注册医生身份)</p>
			</div>
		</div>
	</div>
</template>
<script>
import { doctorCertification } from '@/request/api/doctor-withdraw';
export default {
	data () {
		return {
			name: '',
			btnEnable: false,
			isDoctor: false
		};
	},
	created () {
		// window.localStorage.setItem('backUrl', '');
		const doctor = JSON.parse(window.sessionStorage.getItem('doctorInfo'));
		if (doctor) this.isDoctor = doctor.isDoctor;
		if (!this.isDoctor) {
			document.title = '提示';
		}
	},
	methods: {
		onChangeText () {
			// this.$root.go('/doctor-withdraw-apply');
			this.btnEnable = false;
			if (this.name) {
				this.btnEnable = true;
			}
		},
		handleSubmit () {
			const user = JSON.parse(window.sessionStorage.getItem('userInfo'));
			doctorCertification({
				userId: user.id,
				realName: this.name
			}).then(data => {
				if (data && data === 'retry') {
					this.handleSubmit();
				} else if (data) {
					this.$root.replace('doctor-withdraw');
					// console.log('data======', data);
				}
			});
			// this.$root.go('/doctor-withdraw-list');
		}
	}
};
</script>
<style lang="less" scoped>
	.wrap {
		.content-center {
			margin: 10px 0 0 0;
			background: #FFFFFF;
			box-shadow: 0px 5px 18px -4px rgba(16,31,46,0.04);
			border-radius: 4px;
			padding: 24px 6px 32px 6px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			/deep/ .van-field__label {
				width: auto;
			}
		}
		.enabled {
			opacity: 1;
		}
		.disabled {
			opacity: 0.5;
		}
		.center-button {
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
			border-radius: 21px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 12px 0;
			margin-top: 39px;
			span {
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 16px;
			}
		}
		.tips {
			margin-top: 30px;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			div {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 20px;
			}
		}
	}
</style>